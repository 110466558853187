import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_5/sub_2/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      hoverDot: mdx(
        frontmatter: { language: { eq: "RU" }, title: { eq: "new-world-dots" } }
      ) {
        exports {
          hoverInfo {
            positionLeft
            positionTop
            insideText
            identifier
            main
            tooltip
          }
        }
      }
      mdx(
        frontmatter: { language: { eq: "RU" }, title: { eq: "slide-5-2-1" } }
      ) {
        body
      }
      modal5211: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5211" }
        }
      ) {
        body
      }
      modal5212: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5212" }
        }
      ) {
        body
      }
      modal5213: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5213" }
        }
      ) {
        body
      }
      modal5214: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5214" }
        }
      ) {
        body
      }
      modal5215: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5215" }
        }
      ) {
        body
      }
      modal5216: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5216" }
        }
      ) {
        body
      }
      modal5217: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5217" }
        }
      ) {
        body
      }
      modal5218: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5218" }
        }
      ) {
        body
      }
      modal5219: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal5219" }
        }
      ) {
        body
      }
      modal52110: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "new-world-modals" }
          selector: { eq: "modal52110" }
        }
      ) {
        body
      }
      caption: mdx(
        frontmatter: { language: { eq: "RU" }, title: { eq: "slide-4-6-1" } }
      ) {
        body
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
